<template>
	<autocomplete-field label="Organization" @input="orgSearch" v-model="keyword" :options="orgOptions" @select="orgSelect" autocomplete="nope" :loading="orgLoading" v-bind="$attrs" />
</template>

<script>
import AutocompleteField from '@/components/AutocompleteField'

export default {
	name: 'AutocompleteOrganization',
	components: {
		AutocompleteField,
	},
	data: () => ({
		keyword: null,
		orgOptions: [],
		orgLoading: false,
	}),
	computed: {
		organizations() {
			const orgs = this.$store.getters['organization/organizations']
			return orgs.map(o => { return { value: o.orgId, label: o.nameAddress.join(' - ') }} )
		},
	},
	async mounted() {
		await this.$store.dispatch('organization/listPublic')
	},
	methods: {
		async orgSearch(value) {
			if (!value || value.length < 4) return ''
			this.orgLoading = true
			const results = await this.$store.dispatch('organization/listPublic', { key: 'keywords.name', op: 'array-contains', value })
			this.orgOptions = results.filter(r => r.status != 'removed').map(r => { return { value: r.orgId, label: r.nameAddress.join(' - ') }})
			this.orgLoading = false
		},
		orgSelect(selected) {
			this.keyword = selected.label
			this.$emit('selected', selected)
		},
	},
	watch: {
		$attrs(newVal, oldVal) {
			if (!newVal.value && oldVal.value) {
				this.keyword = ''
			}
			if (newVal.label) {
				this.keyword = newVal.label
			}
		},
	},
}
</script>

<style scoped lang="scss">

</style>
